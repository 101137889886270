/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, Fullmap, FullmapWrap, ColumnWrap, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Stravování"}>
        <ColumnCover name={"menu"}>
        </ColumnCover>


        <Column className="--menu pb--10 pt--10" menu={true}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box">
              
              <Subtitle className="subtitle-box" content={"<a href=\"/\">Mateřská škola <span style=\"font-weight: bold;\">Litultovice</span></a><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/"} target={""} content={"<span style=\"color: rgb(115, 71, 156);\">Aktuálně</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/dokumenty"} target={""} content={"<span style=\"color: rgb(235, 131, 207);\">Dokumenty</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/pro-rodice"} target={""} content={"<span style=\"color: rgb(55, 116, 222); text-decoration: underline;\">Pro <span style=\"text-decoration: underline;\">rodiče</span></span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/fotogalerie"} target={""} content={"<span style=\"caret-color: rgb(221, 116, 15); color: rgb(221, 116, 15);\">Fotogalerie</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/o-nas"} target={""} content={"<span style=\"color: rgb(16, 156, 69);\">O nás</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/kontakt"} target={""} content={"<span style=\"color: rgb(213, 4, 57);\">Kontakt</span>"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="css-qbd661 --justify pb--80 pr--0 pt--80" style={{"marginBottom":0,"backgroundColor":"var(--color-variable-1)"}} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=3000x_.jpg);
    }
  
    `}>
        </Column>


        <Fullmap className="--style2" name={"xrq5c45sffc"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <ColumnCover name={"8nlzg5iq4eb"}>
        </ColumnCover>


        <Column className="--menu pb--10 pt--10" style={{"backgroundColor":"rgba(222,222,222,1)"}} menu={true}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box">
            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style3">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/stravovani"} target={""} content={"<span style=\"text-decoration: underline;\">Stravování</span>"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="pb--40 pt--40" name={"p79ybpodfmq"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":1460}} columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":934}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">STRAVOVÁNÍ DĚTÍ MATEŘSKÉ ŠKOLY LITULTOVICE ZAJIŠŤUJE:</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"<span style=\"font-weight: bold;\">Školní jídelna Stěbořice při Mateřské škole Stěbořice příspěvkové organizaci. <br></span><br>Ředitelka školy: Bc. Miroslava Stonišová <br>Vedoucí jídelny: Helena Morysová <br><span style=\"font-weight: bold;\"><span style=\"color: rgb(4, 4, 5);\">Telefon : 553 661 029 </span><br></span><br><span style=\"font-weight: bold;\"><span style=\"color: rgb(221, 40, 15);\">Stravné je možné odhlásit den předem do 12.00 hodin. Pouze v pondělí lze odhlásit/přihlásit obědy ráno do 8.00 hod v MŠ Litultovice telefonicky – 553 668 127.&nbsp;</span><br></span><br><span style=\"font-weight: bold;\">Aktuální jídelníček k nahlédnutí <a href=\"https://www.strava.cz/Strava/Stravnik/Jidelnicky\">zde.</a>&nbsp;<span style=\"background-color: rgba(202, 54, 54, 0);\"><span style=\"color: rgb(2, 2, 2);\">Zadejte kód jídelny - 10745, nebo vyhledejte v seznamu jídelen Stěbořice. Pro školku platí přesnídávka, oběd MŠ a svačina.</span><br></span></span><br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--02" name={"1cikpuj89cx"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Button className="btn-box" use={"file"} href={"https://cdn.swbpg.com/d/13139/informace-k-stravovani-2024.docx"} content={"Informace ke stravování"} document={"97ca74d38ae34063bdcd75839d9938fb"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Button className="btn-box" use={"file"} href={"https://cdn.swbpg.com/d/13139/informace-o-platbe-stravneho-2024.docx"} content={"Informace o platbě stravného"} document={"e7630b137fbc4f30ac4465cd62087c83"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}